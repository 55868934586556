/* eslint-disable import/no-anonymous-default-export */
import moment from "moment";

const initialState = {
  token: localStorage.getItem("manager_token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  isSocketLoading: true,
  loginFailed: false,
  notifications: [],
  isLoadingNotifications: true,
  entryPage: "/",
  alert: null,
  sidebarOpen: false,
  refresh: [],
  theme: "dark",
};

export default function (state = initialState, action) {
  // console.log("=================");
  // console.log(action.type);
  // console.log("-----------------");
  // console.log(action.payload);

  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        token: localStorage.getItem("manager_token"),
      };
    case "SIDEBAR_SWITCH":
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case "SOCKET_LOADED":
      return {
        ...state,
        client: action.payload,
        isSocketLoading: false,
      };
    case "LOGIN_SUCCESS":
      localStorage.setItem("manager_token", "Token " + action.payload.token);
      return {
        ...state,
        token: "Token " + action.payload.token,
        loginFailed: false,
      };
    case "LOGOUT_SUCCESS":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
      };
    case "PASSWORD_RESET":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
      };
    case "AUTH_ERROR":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isSocketLoading: true,
        isLoading: true,
        messages: null,
        message: null,
        loginFailed: true,
      };
    case "RESET_REDIRECT_LOGIN":
      return {
        ...state,
        loginFailed: false,
      };
    case "ALERT":
      return {
        ...state,
        alert: {
          title: action.payload.title,
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alert: null,
      };
    case "NOTIFICATIONS_LOADED":
      return {
        ...state,
        notifications: action.payload,
        isLoadingNotifications: false,
      };
    case "ENTRY_PAGE":
      return {
        ...state,
        entryPage: action.payload,
      };
    case "REFRESHED":
      return {
        ...state,
        refresh: action.payload,
      };
    case "REFRESH_DATA":
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
}
