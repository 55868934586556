import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";

function Activity() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [sortByDate, setSortByDate] = useState(false);

  const [userIndex, setUserIndex] = useState(0);
  const [activity, setActivity] = useState([]);
  const [ogActivity, setOGActivity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    var tempActivity = Array.from(ogActivity);
    var dayActivity = [];
    console.log(tempActivity);
    for (var i = 0; i < tempActivity.length; i++) {
      console.log(
        moment(tempActivity[i]["datetime"], "MM/DD/YYYY, hh:mm:ss").format(
          "Do MMMM, YYYY"
        )
      );
      if (
        moment(tempActivity[i]["datetime"], "MM/DD/YYYY, hh:mm:ss").isSame(
          moment(date),
          "date"
        )
      ) {
        dayActivity.push(tempActivity[i]);
      }
      setActivity(dayActivity);
    }
  };

  function handleCurrentPage(val) {
    var tempActivity = Array.from(ogActivity);
    setCurrentPage(val);
    setActivity(tempActivity.splice((val - 1) * 25, 25));
  }

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data);
      setOGActivity(
        res.data[0]["activity"] == null ? [] : res.data[0]["activity"]
      );
      setActivity(
        res.data[0]["activity"] == null
          ? []
          : res.data[0]["activity"].splice(0, 25)
      );
      setIsLoading(false);
    });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setOGActivity(users[e.target.value]["activity"]);
    setActivity(Array.from(users[e.target.value]["activity"]).splice(0, 25));
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getPageContent() {
    return (
      <Fragment>
        <Grid container spacing={1.5}>
          <Grid item md={9}>
            <Grid container spacing={1.5}>
              {activity.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No actvity recorded for this user
                    </h4>
                  </div>
                </div>
              ) : (
                <Grid item md={12}>
                  {Array.from(
                    Array(Math.ceil(activity.length / 25)).keys()
                  ).map((temp, index) => (
                    <div
                      style={{
                        cursor: "pointer",
                        margin: "3px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                        borderRadius: "30px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryButtonTextColor
                            : themeStyle.primaryButtonTextColor,
                      }}
                      onClick={() => {
                        handleCurrentPage(index + 1);
                      }}
                    >
                      {index + 1}
                    </div>
                  ))}
                  <br />
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      borderRadius: "15px",
                      padding: "15px",
                      boxShadow: "0px 0px 30px #ccc",
                      width: "40%",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{
                        justifyContent: "flex-start",
                        textAlign: "left",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        paddingBottom: "20px",
                        borderBottom:
                          state.auth.theme == "dark"
                            ? "1px solid" + themeStyleDark.primaryColor
                            : "1px solid" + themeStyle.primaryColor,
                      }}
                    >
                      <Grid item md={2}>
                        No.
                      </Grid>
                      <Grid item md={5} style={{ textAlign: "center" }}>
                        Activity
                      </Grid>
                      <Grid item md={5} style={{ textAlign: "center" }}>
                        Time
                      </Grid>
                    </Grid>

                    {activity.map((acti, index) => (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          color: "black",
                          padding: "15px",
                        }}
                      >
                        <Grid item md={2}>
                          {(currentPage - 1) * 25 + (index + 1)}
                        </Grid>

                        <Grid item md={5} style={{ textAlign: "center" }}>
                          {acti["type"]}
                        </Grid>
                        <Grid style={{ textAlign: "center" }} item md={5}>
                          {acti["datetime"]}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={3}>
            <span
              style={{
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontSize: "14px",
              }}
            >
              Select User
            </span>
            <Select
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
              }}
              variant={"outlined"}
              fullWidth
              value={userIndex}
              onChange={(e) => {
                handleUserChange(e);
              }}
            >
              {getUsersList()}
            </Select>
            <span
              style={{
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontSize: "16px",
              }}
            >
              Choose a date
            </span>
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{
                  height: "40px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
                inputFormat="dd-MM-yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    style={{
                      width: "100%",
                      marginTop: "2px",
                      marginBottom: "5px",
                      height: "40px",
                    }}
                    size="small"
                    fullWidth
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <br /> <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  setActivity(Array.from(ogActivity).splice(0, 25));
                }}
                style={{
                  height: "30px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryButtonTextColor
                      : themeStyle.primaryButtonTextColor,
                }}
                variant="contained"
              >
                View All Dates
              </Button>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_staff || state.auth.user.is_hr) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />

              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor: themeStyle.backgroundColor,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        return <Navigate to="/dashboard" />;
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Activity;
