import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";
import Switch from "@mui/material/Switch";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Resignations() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [resignationSubject, setResignationSubject] = useState("");
  const [resignationDescription, setResignationDescription] = useState("");

  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];

  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [switc, setSwitch] = useState(false);

  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const [isLoading, setIsLoading] = useState(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resignationId, setResignationId] = useState("");
  const [email, setEmail] = useState("");

  const [currentResignations, setCurrentResignations] = useState([]);

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function submitResignation() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["subject"] = resignationSubject;
    body["description"] = resignationDescription;
    body["date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["upload_date"] = moment().format("DD-MM-YYYY");
    axios
      .post(API_URL + "add_resignation", body, config)
      .then(() => {
        setIsSubmitting(false);
        dispatch(loadUser());
        setOpen2(false);
        setResignationDescription("");
        setResignationSubject("");
        createAlert("SUCCESS", "Success.", "Resignation request submitted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function submitSwitch(email, resignation) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = email;

    body["user"] = state.auth.user.email;

    body["resignation"] = resignation;
    body["upload_date"] = moment().format("DD-MM-YYYY");
    axios
      .post(API_URL + "change_resignation_status", body, config)
      .then(() => {
        setIsSubmitting(false);
        getData();
        createAlert("SUCCESS", "Success.", "Resignation visibility updated.");
        setOpen3(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function deleteResignation() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["resignation_id"] = resignationId;

    axios
      .post(API_URL + "delete_resignation", body, config)
      .then(() => {
        setIsSubmitting(false);
        getData();
        createAlert("SUCCESS", "Success.", "Resignation request deleted.");
        setOpen3(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setSwitch(users[e.target.value]["resignation"]);
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_data", body, config).then((res) => {
      setCurrentResignations(res.data["resignations"]);

      body["email"] = state.auth.user.email;
      axios.post(API_URL + "get_users", body, config).then((res) => {
        setUsers(res.data);
        setSwitch(res.data[0]["resignation"]);
        setIsLoading(false);
      });
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (
        state.auth.user.is_staff ||
        state.auth.user.is_manager ||
        state.auth.user.is_hr
      ) {
        getData();
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      getData();
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getPageContent() {
    if (state.auth.user.is_staff || state.auth.user.is_hr) {
      return (
        <Grid container spacing={2}>
          <Grid item md={8}>
            {currentResignations.length == 0 ? (
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>No resignation requests.</h4>
                </div>
              </div>
            ) : (
              ""
            )}
            {currentResignations.map((resignation, index) => (
              <Box
                style={{
                  borderRadius: "6px",
                  border: "1.3px solid #d7ecff",
                  backgroundColor: themeStyle.primaryCardColor,
                  boxShadow: "none",
                  padding: "15px",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    margin: "-5px 0px 0px 0px",
                    fontSize: "18px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.secondaryButtonTextColor
                        : themeStyle.secondaryButtonTextColor,
                  }}
                >
                  {resignation["email"]}
                </div>
                <div style={{ fontSize: "14px" }}>{resignation["subject"]}</div>

                <div style={{ margin: "0px 0px 0px 0px" }}>
                  {resignation["description"]}
                </div>
                <div style={{ marginTop: "-5px" }}>
                  {moment(resignation["date"], "DD-MM-YYYY").format(
                    "Do MMMM, YYYY"
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "-5px",
                    marginRight: "-5px",
                  }}
                >
                  <Fab
                    size="small"
                    style={{
                      transform: "scale(0.8)",
                      boxShadow: "none",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                    onClick={() => {
                      setResignationId(resignation["id"]);
                      setEmail(resignation["email"]);
                      setSelectedDate(resignation["date"]);
                      setOpen3(true);
                    }}
                    aria-label="add"
                  >
                    <DeleteIcon
                      style={{
                        color: "white",
                      }}
                    />
                  </Fab>
                </div>
              </Box>
            ))}
          </Grid>
          <Grid item md={4} style={{ marginTop: "-23px" }}>
            <h3>Submit resignation Visibility</h3>
            <span
              style={{
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontSize: "14px",
              }}
            >
              Select User
            </span>
            <Select
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
              }}
              variant={"outlined"}
              fullWidth
              value={userIndex}
              onChange={(e) => {
                handleUserChange(e);
              }}
            >
              {getUsersList()}
            </Select>
            <br /> <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Switch
                onChange={(e) => {
                  var tempUsers = Array.from(users);
                  tempUsers[userIndex]["resignation"] = e.target.checked;
                  setUsers(tempUsers);
                  submitSwitch(tempUsers[userIndex]["email"], e.target.checked);
                }}
                checked={users[userIndex]["resignation"]}
                inputProps={{
                  "aria-label": "Resignation Visibility",
                }}
              />{" "}
              Resignation Visibility
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: themeStyle.primaryCardColor,
              borderRadius: "15px",
              padding: "15px",
              boxShadow: "0px 0px 30px #ccc",
            }}
          >
            <div
              style={{
                marginTop: "-25px",
                marginBottom: "-15px",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <h3>Apply for Resignation</h3>
            </div>

            <div
              style={{
                margin: "15px 0px 5px 0px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontSize: "16px",
              }}
            >
              Subject
            </div>
            <TextField
              value={resignationSubject}
              style={{ marginTop: "2px" }}
              onChange={(e) => setResignationSubject(e.target.value)}
              fullWidth
              placeholder="Reason for resignation"
              variant="outlined"
              size="small"
            />
            <br></br>

            <div
              style={{
                margin: "15px 0px 5px 0px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontSize: "16px",
              }}
            >
              Description
            </div>
            <TextField
              value={resignationDescription}
              style={{ marginTop: "2px" }}
              onChange={(e) => setResignationDescription(e.target.value)}
              fullWidth
              placeholder="Describe in detail"
              variant="outlined"
              size="small"
              multiline={true}
              maxRows={4}
              rows={4}
            />
            <br></br>
            <br></br>

            <Button
              onClick={() => {
                setOpen2(true);
              }}
              style={{
                width: "100%",
                fontSize: "15px",
                height: "35px",
                marginTop: "-1px",
                backgroundColor: themeStyle.primaryColor,
              }}
              variant="contained"
              component="span"
            >
              <p>Submit Resignation Request Application</p>
            </Button>
            {isSubmitting ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  }
  console.log(users[userIndex]);
  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeStyle.backgroundColor,
              }}
            >
              <CircularProgress style={{ color: themeStyle.primaryColor }} />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={() => setOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: themeStyle.backgroundColor,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  <div style={{ marginTop: "-25px" }}>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton size="small">
                        <CloseIcon
                          onClick={() => setOpen(false)}
                          style={{
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        />
                      </IconButton>
                    </div>
                    <h3>Delete this resignation request?</h3>
                    <span>This action cannot be reversed.</span>
                    <br></br>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setOpen(false)}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        NO
                      </Button>
                      <Button
                        style={{ height: "30px", marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => setOpen(true)}
                      >
                        YES
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open2}
              onClose={() => setOpen2(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open2}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: themeStyle.backgroundColor,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  <div style={{ marginTop: "-25px" }}>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton size="small">
                        <CloseIcon
                          onClick={() => setOpen2(false)}
                          style={{
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        />
                      </IconButton>
                    </div>
                    <h3>Submit resignation request?</h3>
                    <br></br>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setOpen2(false)}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        NO
                      </Button>
                      <Button
                        style={{ height: "30px", marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => submitResignation()}
                      >
                        YES
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open3}
              onClose={() => setOpen3(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open3}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: themeStyle.backgroundColor,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  <div style={{ marginTop: "-25px" }}>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton size="small">
                        <CloseIcon
                          onClick={() => setOpen3(false)}
                          style={{
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        />
                      </IconButton>
                    </div>
                    <h3>Delete this resignation request?</h3>
                    <br></br>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setOpen3(false)}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        NO
                      </Button>
                      <Button
                        style={{ height: "30px", marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => deleteResignation()}
                      >
                        YES
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <div
              style={{
                padding: "100px 50px 90px 115px",
                backgroundColor: themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
              }}
            >
              {getPageContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Resignations;
