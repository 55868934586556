import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import { tr } from "date-fns/locale";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { themeStyle, themeStyleDark } from "../components/Components";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Events() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    console.log(type, title, message);
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [toggleDrawer, setToggleDrawer] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [select, setSelect] = useState("EVENTS");
  const [eventName, setEventName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDescription, setEventDescription] = useState("");

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value) => {
    setSelect(value);
    setSelectedPage(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
    setSelect(event.currentTarget.value);
  };

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "EVENTS"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [importantDatesList, setimportantDatesList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [deleteEventName, setDeleteEventName] = useState("");

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body = JSON.stringify(body);

    axios.post(API_URL + "get_data", body, config).then((res) => {
      setEventsList(res.data["events"]);
      setimportantDatesList(res.data["important_dates"]);
      setHolidayList(res.data["holidays"]);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function deleteEvent() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["event_id"] = id;
    body["event_type"] = type;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/delete_event", body, config)
      .then((res) => {
        getData();
        handleClose();
        createAlert("SUCCESS", "Success.", "Event deleted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function addEvent() {
    // setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["event_id"] = makeid(5);
    body["event_type"] = select;
    body["event_date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["event_name"] = eventName;
    body["event_location"] = eventLocation;
    body["event_description"] = eventDescription;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "add_event", body, config)
      .then((res) => {
        getData();
        createAlert("SUCCESS", "Success.", "Event added.");
        // setToggleDrawer(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getContent() {
    if (selectedPage == "EVENTS") {
      return (
        <Grid container spacing={1.5}>
          {eventsList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No upcoming events.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          {eventsList.map((event, index) => (
            <Grid item md={4}>
              <Box
                padding={1}
                borderRadius="5px"
                sx={{ "&:hover": { opacity: "1 !important" } }}
                style={{
                  opacity: moment(event["date"], "DD-MM-YYYY").isBefore(
                    moment()
                  )
                    ? "0.5"
                    : "1",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  backgroundColor: themeStyle.primaryCardColor,
                  boxShadow: "none",
                  height: "100%",
                  marginBottom: "5px",
                }}
              >
                <Box style={{ marginLeft: "5px" }}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item md={10}>
                      <div
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonTextColor
                              : themeStyle.secondaryButtonTextColor,
                          fontSize: "19px",
                        }}
                      >
                        {event["name"]}
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {moment(event["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {event["venue"]}
                      </div>
                      <div
                        style={{
                          margin: "10px 0px 0px 0px",
                          fontSize: "15px",
                        }}
                      >
                        {event["description"]}
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      {state.auth.user.is_staff ||
                      state.auth.user.is_manager ? (
                        <Button
                          style={{
                            borderRadius: "50px",
                            marginTop: "-10px",

                            height: "50px",
                            width: "50px",
                          }}
                          onClick={() => {
                            setId(event["id"]);
                            setType("EVENTS");
                            setDeleteEventName(event["name"]);
                            setOpen(true);
                          }}
                        >
                          <DeleteIcon
                            style={{
                              color: "#ff3333",
                            }}
                          />
                        </Button>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    } else if (selectedPage == "DATES") {
      return (
        <Grid container spacing={1.5}>
          {importantDatesList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No upcoming important dates.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          {importantDatesList.map((date, index) => (
            <Grid item md={4}>
              <Box
                borderRadius="5px"
                padding={1}
                sx={{ "&:hover": { opacity: "1 !important" } }}
                style={{
                  opacity: moment(date["date"], "DD-MM-YYYY").isBefore(moment())
                    ? "0.5"
                    : "1",
                  minHeight: "100%",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  backgroundColor: themeStyle.primaryCardColor,
                  boxShadow: "none",
                  marginBottom: "5px",
                  height: "100%",
                }}
              >
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item md={10}>
                    <div
                      style={{
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      <b
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonTextColor
                              : themeStyle.secondaryButtonTextColor,
                          fontSize: "18px",
                        }}
                      >
                        {date["name"]}
                      </b>
                      <br></br>
                      <span style={{}}>
                        {moment(date["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    {state.auth.user.is_staff || state.auth.user.is_manager ? (
                      <Button
                        style={{
                          borderRadius: "50px",
                          height: "50px",
                          width: "50px",
                          marginTop: "-10px",
                        }}
                        onClick={() => {
                          setId(date["id"]);
                          setType("DATES");
                          setDeleteEventName(date["name"]);
                          setOpen(true);
                        }}
                        aria-label="add"
                      >
                        <DeleteIcon
                          style={{
                            color: "#ff3333",
                          }}
                        />
                      </Button>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    } else if (selectedPage == "HOLIDAYS") {
      return (
        <Grid container spacing={1.5}>
          {holidayList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No upcoming holidays.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          {holidayList.map((holiday, index) => (
            <Grid item md={4}>
              <Box
                borderRadius="5px"
                padding={1}
                sx={{ "&:hover": { opacity: "1 !important" } }}
                style={{
                  opacity: moment(holiday["date"], "DD-MM-YYYY").isBefore(
                    moment()
                  )
                    ? "0.5"
                    : "1",
                  minHeight: "100%",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  backgroundColor: themeStyle.primaryCardColor,
                  boxShadow: "none",
                  marginBottom: "5px",
                  height: "100%",
                }}
              >
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item md={10}>
                    <div
                      style={{
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      <b
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonTextColor
                              : themeStyle.secondaryButtonTextColor,
                          fontSize: "18px",
                        }}
                      >
                        {holiday["name"]}
                      </b>
                      <br></br>
                      <span style={{}}>
                        {moment(holiday["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    {state.auth.user.is_staff || state.auth.user.is_manager ? (
                      <Button
                        style={{
                          borderRadius: "50px",
                          height: "50px",
                          width: "50px",
                          marginTop: "-10px",
                        }}
                        onClick={() => {
                          setId(holiday["id"]);
                          setType("HOLIDAYS");
                          setDeleteEventName(holiday["name"]);
                          setOpen(true);
                        }}
                        aria-label="add"
                      >
                        <DeleteIcon
                          style={{
                            color: "#ff3333",
                          }}
                        />
                      </Button>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>{" "}
            </Grid>
          ))}
        </Grid>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                marginTop: "46vh",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Drawer
              anchor={"right"}
              open={toggleDrawer}
              onClose={() => setToggleDrawer(false)}
            >
              {isSubmitting ? (
                <div
                  style={{
                    marginTop: "60px",
                    padding: "20px",
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                  <h3>Uploading in progress...</h3>
                </div>
              ) : (
                <div style={{ padding: "15px", marginTop: "23px" }}>
                  <div
                    style={{
                      marginTop: "35px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,

                      fontSize: "14px",
                    }}
                  >
                    Select type
                  </div>
                  <Select
                    value={select}
                    onChange={(e) => handleSelect(e.target.value)}
                    style={{ marginTop: "2px", height: "40px" }}
                    variant={"outlined"}
                    fullWidth
                  >
                    <MenuItem value={"EVENTS"}>Event</MenuItem>
                    <MenuItem value={"DATES"}>Important Date</MenuItem>
                    <MenuItem value={"HOLIDAYS"}>Holiday</MenuItem>
                  </Select>
                  <div
                    style={{
                      marginTop: "5px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,

                      fontSize: "14px",
                    }}
                  >
                    Date
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      value={selectedDate}
                      sx={{
                        height: "40px",
                        width: "100%",
                        marginTop: "5px",
                        marginBottom: "20px",
                      }}
                      onChange={handleDateChange}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          style={{
                            width: "100%",
                            marginTop: "2px",
                            marginBottom: "5px",
                            height: "40px",
                          }}
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <br></br>
                  <span
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,

                      fontSize: "14px",
                    }}
                  >
                    Name
                  </span>
                  <TextField
                    style={{ marginTop: "2px" }}
                    value={eventName}
                    fullWidth
                    size="small"
                    onChange={(e) => setEventName(e.target.value)}
                    placeholder={select + " Name"}
                    variant="outlined"
                  />
                  <br></br>
                  {select == "EVENTS" ? (
                    <div style={{ marginTop: "5px" }}>
                      <span
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,

                          fontSize: "14px",
                        }}
                      >
                        Location
                      </span>
                      <TextField
                        style={{ marginTop: "2px" }}
                        fullWidth
                        value={eventLocation}
                        onChange={(e) => setEventLocation(e.target.value)}
                        size="small"
                        placeholder="Event Location"
                        variant="outlined"
                      />
                      <div
                        style={{
                          marginTop: "15px",
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,

                          fontSize: "14px",
                        }}
                      >
                        Event Description
                      </div>
                      <TextField
                        style={{ marginTop: "2px" }}
                        multiline
                        value={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                        rows={4}
                        fullWidth
                        variant="outlined"
                        placeholder={select + " Description"}
                      />
                      <br></br>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <br></br>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      height: "35px",
                    }}
                  >
                    <Button
                      onClick={() => addEvent()}
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: themeStyle.primaryColor,
                        boxShadow: "none",
                      }}
                    >
                      <h4>Add event</h4>
                    </Button>
                  </Box>
                </div>
              )}
            </Drawer>

            <Sidebar
              tabs={
                <div style={{ display: "flex", marginRight: "-160px" }}>
                  <Grid container spacing={1.5}>
                    <Grid item md={1}>
                      {state.auth.user.is_staff ||
                      state.auth.user.is_manager ? (
                        <Button
                          onClick={() => setToggleDrawer(true)}
                          style={{
                            width: "20px",
                            height: "30px",
                            borderRadius: "15px",
                            backgroundColor: "#ff3333",
                            margin: "0px -50px 0px 10px",
                          }}
                        >
                          <AddIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </Button>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {selectedPage == "EVENTS" ? (
                        <Button
                          fullWidth
                          value={"EVENTS"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            EVENTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"EVENTS"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            EVENTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {selectedPage == "DATES" ? (
                        <Button
                          fullWidth
                          value="DATES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            DATES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="DATES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            DATES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {selectedPage == "HOLIDAYS" ? (
                        <Button
                          fullWidth
                          value="HOLIDAYS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            HOLIDAYS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="HOLIDAYS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            HOLIDAYS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: themeStyle.backgroundColor,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  <div style={{ marginTop: "-25px" }}>
                    <h3>Delete this item?</h3>
                    <span>
                      This action cannot be reversed. It will be deleted
                      forever.
                    </span>
                    <br></br>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => deleteEvent()}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        Yes
                      </Button>
                      <Button
                        style={{ height: "30px", marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => setOpen(false)}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <div
              style={{
                padding: "100px 50px 90px 115px",
                backgroundColor: themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            marginTop: "46vh",
          }}
        >
          <CircularProgress />
        </div>
      </Fragment>
    );
  }
}

export default Events;
