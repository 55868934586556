import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import SchoolIcon from "@mui/icons-material/School";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Vaccines from "@mui/icons-material/Vaccines";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement,
  zoomPlugin
);

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [leaves, setLeaves] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["send_to"] = selectedNoti;
    body["title"] = subject;
    body["message"] = message;

    axios.post(API_URL + "send_notification", body, config).then((res) => {
      createAlert("SUCCESS", "Sent.", "Notification sent!");
    });
  }

  const graphState = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Users per month",
          fill: false,
          lineTension: 0.3,
          borderRadius: 100,
          borderSkipped: false,
          backgroundColor: "#08A045",
          borderColor: "#08A045",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          data: [25, 39, 20, 31, 26, 45, 20, 20, 41, 46, 55],
          barThickness: 20,
        },
        {
          label: "Users per month",
          fill: false,
          lineTension: 0.3,
          borderRadius: 100,
          borderSkipped: false,
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.primaryColor
              : themeStyle.primaryColor,
          borderColor:
            state.auth.theme == "dark"
              ? themeStyleDark.primaryColor
              : themeStyle.primaryColor,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          data: [55, 40, 80, 81, 56, 55, 65, 59, 80, 81, 56],
          barThickness: 20,
        },
      ],
    },
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  function getData() {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    axios.get(API_URL + "get_dashboard", config).then((res) => {
      setTotalUsers(res.data["total_users"]);
      setLeaves(res.data["leaves"]);
      setIsLoading(false);
    });
  }

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeStyle.backgroundColor,
              }}
            >
              <CircularProgress style={{ color: themeStyle.primaryColor }} />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "HOME" ? (
                      <Button
                        fullWidth
                        value={"HOME"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          HOME
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value={"HOME"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonColor
                              : themeStyle.secondaryButtonColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonTextColor
                                : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          HOME
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  {/* <Grid item md={4}>
                    {selectedPage == "NOTIFICATIONS" ? (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonColor
                              : themeStyle.secondaryButtonColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: state.auth.theme == "dark"
                        ? themeStyleDark.secondaryButtonTextColor
                        : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              }
            />
            <div
              style={{
                padding: "100px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "30%",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          borderRadius: "15px",
                        }}
                      >
                        <PeopleAlt
                          style={{
                            padding: "10px",
                            color: "white",
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "70%",
                          height: "100%",
                          paddingLeft: "25px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Total Users
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor: "#08A045",
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "30%",
                          backgroundColor: "#08A045",
                          borderRadius: "15px",
                        }}
                      >
                        <PersonIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #08A045",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "70%",
                          height: "100%",
                          paddingLeft: "25px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Active Users
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "25vh",
                        borderRadius: "15px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      padding: "17px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "30%",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          borderRadius: "15px",
                        }}
                      >
                        <CurrencyRupeeIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "70%",
                          height: "100%",
                          paddingLeft: "25px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                          }}
                        >
                          Total Payslips
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "60px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Kanit",
                          }}
                        >
                          {totalStudents}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      marginTop: "6px",
                      padding: "15px",
                      boxShadow: "0px 0px 30px #ccc",
                      borderRadius: "15px",
                      height: "100%",
                    }}
                  >
                    <Line
                      height={120}
                      width={250}
                      plugins={[zoomPlugin]}
                      data={graphState.dataLine}
                      options={{
                        plugins: {
                          zoom: {
                            zoom: {
                              wheel: {
                                enabled: true,
                              },
                              pinch: {
                                enabled: true,
                              },
                              mode: "xy",
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        borderColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        responsive: true,
                        animation: { duration: 0 },
                        title: {
                          display: true,
                          text: "New user data",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "20px",
                      borderRadius: "15px",
                      marginTop: "7px",
                      height: "100%",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "5px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        marginBottom: "35px",
                      }}
                    >
                      Send Notifications
                    </h2>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item md={4}>
                        {selectedNoti == "EVERYONE" ? (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: themeStyle.backgroundColor,
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "EMPLOYEES" ? (
                          <Button
                            fullWidth
                            value="EMPLOYEES"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EMPLOYEES{" "}
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EMPLOYEES"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: themeStyle.backgroundColor,
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              EMPLOYEES{" "}
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "HR & MANAGER" ? (
                          <Button
                            fullWidth
                            value="HR & MANAGER"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              HR & MANAGER{" "}
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="HR & MANAGER"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: themeStyle.backgroundColor,
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              HR & MANAGER{" "}
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Subject"
                      variant="outlined"
                      value={subject}
                      size="small"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <br></br> <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Message"
                      variant="outlined"
                      size="small"
                      style={{
                        marginBottom: "25px",
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <br></br> <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          fontSize: "20px",
                          marginTop: "-5px",
                          height: "35px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                        onClick={() => sendNotification()}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            color: "white",
                          }}
                        >
                          Send
                        </span>
                        <ChevronRightIcon
                          style={{
                            fontSize: "30px",
                            color: "white",
                            marginRight: "-10px",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
